import {configure, extend, localize} from "vee-validate";
import {email, min, required, regex, digits, numeric, max } from "vee-validate/dist/rules";
// import {i18n} from "./lang/i18n";
import Vue from "vue";

// configure({
//   defaultMessage: (field, values) => {
//     // override the field name.
//     values._field_ = i18n.t(`fields.${field}`);
//
//     return i18n.t(`validation.${values._rule_}`, values);
//   }
// });

// Install required rule and message.
extend("required", required);
extend("max", max);

// Install email rule and message.
extend("email", email);

// Install regex rule and message.
extend("regex", regex);

// Install min digits and message.
extend("digits", digits);

// Install min digits and message.
extend("numeric", numeric);

extend('same', {
    validate(value, args) {
        return value !== args[0];
    },
    message: 'メールは担当者のメールと重複しています。もう一度ご入力してください。'
});


import ja from "vee-validate/dist/locale/ja.json";

localize({
    en: {
        messages: ja.messages,
        names: {
            user: "ユーザー",
            num: "増減値",
            reason: "理由"
        },
        fields: {
            user: {
                required: 'メンションがありません、メンションを記入してください。'
            },
            message: {
                required: '内容がありません、内容を記入してください'
            },
            user2: {
                required: 'メンションがありません、メンションを記入してください。'
            },
            message2: {
                required: '内容がありません、内容を記入してください'
            }
        }
    }
});

let LOCALE = "ja";

// A simple get/set interface to manage our locale in components.
// This is not reactive, so don't create any computed properties/watchers off it.
Object.defineProperty(Vue.prototype, "locale", {
    get() {
        return LOCALE;
    },
    set(val) {
        console.log(val)
        LOCALE = val;
        localize(val);
    }
});
