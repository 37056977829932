var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("footer", [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-auto" }, [
              _c("a", { attrs: { href: "/howto/" } }, [_vm._v("使い方")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-auto" }, [
              _c("a", { attrs: { href: "/terms/" } }, [_vm._v("利用規約")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-auto" }, [
              _c("a", { attrs: { href: "/privacy/" } }, [
                _vm._v("プライバシーポリシー")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-auto" }, [
              _c("a", { attrs: { href: "/contact/" } }, [_vm._v("問い合わせ")])
            ])
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "text-center" }, [
            _vm._v("© 2020 Faith Solution Technologies.")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }