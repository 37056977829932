<template>
    <div>
        <admin-header></admin-header>
        <div class="container">

            <div id="Ranking-content">
                <div id="content">
                    <div class="row my-3 tittle-area-p">
                        <div class="col-auto tittle-area"><img src="/images/ranking_icon.png" alt="ランキング"><span class="h4">- ランキング -</span></div>
                        <div class="col-auto ml-auto top-content">
                            <button class="btn btn-secondary button_datepicker" type="button" id="ButtonEventTimeRangeS">指定なし</button>
                            <span class="col-form-label px-2">～</span>
                            <button class="btn btn-secondary button_datepicker" type="button" id="ButtonEventTimeRangeE">指定なし</button>
                        </div>
                        <div class="col-auto top-content">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButtonMode" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">もらった🍩</button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButtonMode">
                                    <a class="dropdown-item" href="./?mode=received">もらった<span class="donut-right">🍩</span></a>
                                    <a class="dropdown-item" href="./?mode=given">あげた<span class="donut-right">🍩</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto top-content">
                            <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButtonChannel" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">All</button>
                                <div class="dropdown-menu tab-all" aria-labelledby="dropdownMenuButtonChannel">
                                    <a class="dropdown-item" href="./?channel=">All</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="search_result">
                        <div class="sear-re-wid">
                            <div class="alert-tx alert alert-info">
                                <span>条件に一致する情報は見つかりませんでした。</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="messageModal" tabindex="-1" role="dialog" aria-labelledby="messageModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="messageModalTitle">&nbsp;</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p id="messageModalBody"></p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<admin-footer></admin-footer>-->
    </div>
</template>

<script>
    export default {
        data: function() {
            return {
                MIX_SLACK_CLIENT_ID: process.env.MIX_SLACK_CLIENT_ID,
                MIX_SLACK_CLIENT_SECRET: process.env.MIX_SLACK_CLIENT_SECRET,
            }
        },
        async created() {
            let code = this.$route.query.code
            if (!_.isEmpty(code)) {
                try {
                    let { data } = await axios.get('https://slack.com/api/oauth.v2.access', { params: {
                            'client_id': process.env.MIX_SLACK_CLIENT_ID,
                            'client_secret': process.env.MIX_SLACK_CLIENT_SECRET,
                            'code': code
                        }})


                    console.log('user info ============')
                    console.log(data)
                    console.log(data.authed_user.access_token)


                    // let oauth2 = await axios.get('https://slack.com/api/oauth.access', { params: {
                    //         'client_id': process.env.MIX_SLACK_CLIENT_ID,
                    //         'client_secret': process.env.MIX_SLACK_CLIENT_SECRET,
                    //         'code': code
                    //     }})
                    //
                    // console.log('user info ============')
                    // console.log(data)

                    let user = await axios.get('https://slack.com/api/users.identity', { params: {
                            'token': data.authed_user.access_token
                        }})

                    console.log('oauth2 info ============')
                    console.log(user)


                    let profile = await axios.get('https://slack.com/api/users.profile.get', { params: {
                            'token': 'xoxp-1223470117184-1184891335527-1232680450758-172e06d0bab3dcee67c32aef32b2b601'
                        }})
                    console.log('profile ================')
                    console.log(profile)
                } catch (e) {
                    console.log('&&&&&&')
                    console.log(e)
                }
            }

        }
    };
</script>

