<template>
    <div>
        <admin-header></admin-header>
        <div class="container">
            <div id="Admin-users">
                <div id="content">
                    <div class="row my-3">
                        <div class="col-auto"><span class="h4"><i class="fas fa-user-cog"></i>ユーザー</span></div>
                    </div>
                    <div id="search_result">
                        <div>
                            <div class="alert alert-info">
                                <span v-if="users && users.total">{{ users.total }} 件中 {{ users.per_page * (page - 1) + 1}} ～ {{ users.per_page * page <= users.total ? users.per_page * page : users.total }} 件目を表示しています。</span>
                                <span v-else>データがありません。</span>
                            </div>
                        </div>
                        <form id="form_update_auth" method="post" action="./ajax_update_auth.php">
                            <input type="hidden" class="token" name="token" value="jsXGkAwr2guQ8DUGZgTBOoxRZNsiOWQHJOCJAbA5">
                            <input type="hidden" class="user_id" name="user_id" value="">
                            <input type="hidden" class="is_app_admin" name="is_app_admin" value="">
                        </form>
                        <form id="form_sync_slack" method="post" action="./ajax_sync_slack.php">
                            <input type="hidden" class="token" name="token" value="mkRx9AfZlZcTLpkgMo3yU8qiEXZOxalpBHWN9IBi">
                            <input type="hidden" class="user_id" name="user_id" value="">
                        </form>
                        <div class="table-responsive admin_users_index">
                            <table class="table table-bordered table-striped table-hover">
                                <thead class="thead-dark">
                                <tr>
                                    <th class="person text-center">ユーザー</th>
                                    <th class="is_app_admin text-center">権限</th>
                                    <th class="sync text-center">同期</th>
                                </tr>
                                </thead>
                                <tbody v-if="users && users.data">
                                <tr v-for="userInfo in users.data">
                                    <td class="person align-middle text-left">
                                        <router-link :to="{name: 'AdminUserDetail', params: {id: userInfo.code}}">
                                            <img style="width: 48px;margin-right: 10px;"
                                                 :src="userInfo.image"
                                                 onerror="this.src='/images/generic_avatar.png'" alt="BSV Vu Truong">
                                            <span>{{ userInfo.label }}</span>
                                        </router-link>
                                    </td>
                                    <td class="total align-middle text-center role">
                                        <b-dropdown v-if="!userInfo.is_owner" id="dropdown-right" :text="userInfo.is_admin ? '管理ユーザー' : '一般ユーザー'" variant="primary" class="m-2">
                                            <b-dropdown-item href="javascript:;" @click="changeRole(userInfo.code, 1)">管理ユーザー</b-dropdown-item>
                                            <b-dropdown-item href="javascript:;" @click="changeRole(userInfo.code, 0)">一般ユーザー</b-dropdown-item>
                                        </b-dropdown>
                                        <span v-else>管理ユーザー</span>
                                    </td>
                                    <td class="total align-middle text-center"><button @click="updateUser(userInfo.user)" type="button" class="btn btn-primary">⟳</button></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="padding-all" v-if="users">
                            <pagination :current-page="users.current_page"
                                        :per-page="users.per_page"
                                        :total="users.total"
                                        @changePage="changePage"
                                        v-if="users.last_page > 1"
                            ></pagination>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="messageModal" tabindex="-1" role="dialog" aria-labelledby="messageModalTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="messageModalTitle">&nbsp;</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p id="messageModalBody"></p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<admin-footer></admin-footer>-->
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    export default {
        data() {
            return {
                users: {},
                page: 1
            }
        },
        computed: {
            ...mapState('user', ['user'])
        },
        methods: {
            async getUsers() {
                try {
                    let {data} = await axios.get('/api/users/list?page='+this.page)
                    this.users = data
                } catch (e) {

                }
            },
            changePage(page) {
                this.page = page
                this.getUsers()

                this.$router.push({name: 'AdminUser', query: {page: this.page}});
            },
            async changeRole(id, role) {
                try {
                    this.$wait.start('loading')
                    let {data} = await axios.post('/api/users/change-role', {user_id: id, is_admin: role})
                    if (data.id) {
                        const index =  _.findIndex(this.users.data, (user) => {
                            return user.code == id
                        })
                        if (index > -1) {
                            this.users.data[index].is_admin = data.is_admin
                        }

                        if (data.id == this.user.id) {
                            this.user.is_admin = data.is_admin
                            this.$store.dispatch('user/SET_USER', this.user)
                        }

                        if (!role && !this.user.is_owner) {
                            this.$router.push({
                                name: 'AdminForm'
                            })
                        }
                    }

                    this.$wait.end('loading')
                } catch (e) {
                    this.$wait.end('loading')
                }
            },
            async updateUser(id) {
                this.$wait.start('loading')
                try {
                    let {data} = await axios.get('/api/users/update?user='+id)
                    let index = _.findIndex(this.users.data, (u) => {
                        return u.code == data.id
                    })

                    if (index > -1) {
                        console.log(this.users.data[index])
                        this.users.data[index]['image'] = data.image ? data.image : this.users.data[index]['image'];
                        this.users.data[index]['label'] = data.real_name ? data.real_name : this.users.data[index]['label'];
                        this.users.data[index]['is_admin'] = data.is_admin ? data.is_admin : this.users.data[index]['is_admin'];
                        this.users.data[index]['is_owner'] = data.is_owner ? data.is_owner : this.users.data[index]['is_owner'];
                    }
                    this.$wait.end('loading')
                } catch (e) {
                    this.$wait.end('loading')
                }
            }
        },
        created() {
            this.getUsers()
        }
    };
</script>

