<template>
    <div>
        <admin-header></admin-header>
        <div class="container">
            <div class="alert alert-success" v-if="successM">{{ successM }}</div>
            <div class="portlet light bordered">
                <!-- <button type="button" class="btn btn-danger" @click="sendEvent">Test event from Slack</button> -->
                <div class="portlet-title">
                    <div class="caption">
                        <i class="fab fa-slack"></i>
                        <span class="caption-subject sbold uppercase">一日一善投稿フォーム</span>
                    </div>
                </div>

                <div class="portlet-body form" id="working-form">
                    <div class="error error2" v-if="err">投稿データを入力してください。</div>
                    <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form" @submit.prevent="sendToSlack()">
                        <div class="form-body">
                            <h6 class="title bold">してあげた</h6>

                            <div class="form-group row position-relative" v-for="(user, i) in userTos">
                                <input type="hidden" v-model="user.type" value="1">
                                <div class="col col-92">
                                    <div class="input-group">
                                        <span class="input-group-btn">
                                            <button class="btn gray" type="button">/to</button>
                                        </span>
                                    </div>
                                </div>
                                <div class="col col-half">
                                    <ValidationProvider name="user"
                                                        :rules="{ 'required': userFromEmpty() || !empty(user.message) }"
                                                        v-slot="{ errors }">
                                        <v-select placeholder="@メンション" multiple :class="{'v-select': true, 'error': errors[0]}" v-model="user.user"
                                                  name="user.user"
                                                  :options="users"
                                        ></v-select>
                                        <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col col-half">
                                    <ValidationProvider name="message"
                                                        :rules="{ 'required': userFromEmpty() || !empty(user.user) }"
                                                        v-slot="{ errors }">
                                    <input type="text" :class="{'form-control': true, 'error': errors[0]}" placeholder="内容" v-model="user.message" :name="user.message">
                                        <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class=" col col-70 d-flex justify-content-end pl-sm-0">
                                    <button class="btn btn-danger circle" type="button" @click="removeRow(i)"><i class="fas fa-minus"></i></button>
                                </div>
                            </div>
                            <div class="btn-plus">
                                <button class="btn btn-primary circle" type="button" @click="addRow()"><i class="fas fa-plus"></i></button>
                            </div>
                        </div>

                        <div class="form-body mt-3">
                            <h6 class="title bold">してもらった</h6>
                            <div class="form-group row position-relative" v-for="(user, i) in userFroms">
                                <input type="hidden" v-model="user.type" value="2">
                                <div class="col col-92">
                                    <div class="input-group">
                                        <span class="input-group-btn">
                                            <button class="btn gray" type="button">/from</button>
                                        </span>
                                    </div>
                                </div>
                                <div class="col col-half">
                                    <ValidationProvider name="user2"
                                                        :rules="{ 'required': userToEmpty() || !empty(user.message) }"
                                                        v-slot="{ errors }">
                                        <v-select multiple placeholder="@メンション" :class="{'v-select': true, 'error': errors[0]}" v-model="user.user"
                                                  :options="users"></v-select>
                                        <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col col-half">
                                    <ValidationProvider name="message2"
                                                        :rules="{ 'required': userToEmpty() || !empty(user.user) }"
                                                        v-slot="{ errors }">
                                        <input type="text" :class="{'form-control': true, 'error': errors[0]}" placeholder="内容" v-model="user.message">
                                        <br v-show="errors[0]"><span class="error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="col col-70 pl-sm-0">
                                    <div class="d-flex justify-content-end">
                                        <button class="btn btn-danger circle" type="button" @click="removeFromRow(i)"><i class="fas fa-minus"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="btn-plus">
                                <button class="btn btn-primary circle" type="button" @click="addFromRow()"><i class="fas fa-plus"></i></button>
                            </div>
                        </div>
                        <div class="form-body col-11 pr-0 text-right">
                            <button type="submit" class="btn btn-primary submit">送信</button>
                        </div>
                    </ValidationObserver>

                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import {mapActions, mapMutations, mapState} from 'vuex'

    export default {
        data() {
            return {
                client_name: '',
                successM: '',
                err: '',
                userTos: [
                    {
                        user: '',
                        message: ''
                    }
                ],
                userFroms: [
                {
                    user: '',
                    message: ''
                },
            ]
            }
        },
        computed: {
            ...mapState('user', ['users', 'user']),
        },
        methods: {
            ...mapMutations('user', ['SET_USERS']),
            ...mapActions('user', ['LIST_USERS']),
            userFromEmpty() {
                let err = 0
                _.forEach(this.userFroms, (from) => {
                    if (from.message || from.user) {
                        err++
                    }
                })

                if (err > 0) {
                    return false
                } else {
                    return true
                }
            },
            userToEmpty() {
                let err = 0
                _.forEach(this.userTos, (to) => {
                    if (to.message || to.user) {
                        err++
                    }
                })

                if (err > 0) {
                    return false
                } else {
                    return true
                }
            },
            empty(text) {
                return _.isEmpty(text)
            },
            addRow() {
                this.userTos.push({
                    user: '',
                    message: ''
                })
            },
            addFromRow() {
                this.userFroms.push({
                    user: '',
                    message: ''
                })
            },
            removeRow(i) {
                this.userTos.splice(i, 1);
            }
            ,
            removeFromRow(i) {
                this.userFroms.splice(i, 1);
            },
            async sendToSlack() {
                const isValid = await this.$refs.observer.validate();
                if (!isValid) {
                    setTimeout(() => {
                        const errors = Object.entries(this.$refs.observer.errors)
                            .map(([key, value]) => ({key, value}))
                            .filter(error => error["value"].length);
                        this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
                            behavior: "smooth",
                            block: "center"
                        });
                    }, 100);

                    return;
                }

                this.$refs.observer.reset();
                if (this.userTos.length === 0 && this.userFroms.length === 0) {
                    this.err = '投稿データを入力してください。'
                }

                if (this.userTos.length > 0 || this.userFroms.length > 0) {
                    this.$wait.start('loading')
                    if (this.userTos.length > 0) {
                        try {
                            let data = await axios.post('/api/users/send-message',  {userTos: this.userTos, userFroms: this.userFroms})
                            if (data) {
                                this.userTos = [
                                    {
                                        user: '',
                                        message: ''
                                    }]
                                this.userFroms = [
                                    {
                                        user: '',
                                        message: ''
                                    }]
                            }
                            this.successM = 'Slackに投稿されました。'
                            setTimeout(() => {
                                this.successM = ''
                            }, 3000)
                            this.$wait.end('loading')
                        } catch (e) {
                            this.$wait.end('loading')
                            console.log(e)
                        }
                    }
                }

            },
            async sendEvent() {
                var message = {
                      "token": "rD5NXb0lTivig10ZV3mxM9D8",
                      "team_id": "T016KDU3F5E",
                      "api_app_id": "A0170GC6P5F",
                      "event": {
                        "type": "message",
                        "text": "/from <@U017WEK06G0|thangnguyen> hi",
                        "user": "U015ES79VFH",
                        "ts": "1595410181.001100",
                        "team": "T016KDU3F5E",
                        "channel": "C0174A9HH0A",
                        "event_ts": "1595410181.001100",
                        "channel_type": "channel"
                    },
                    "type": "event_callback",
                    "event_id": "Ev017Q2CQG76",
                    "event_time": 1595410181,
                    "authed_users": [
                    "U016MTEJHSN"
                    ]
                };
                var message1 = {
                      "token": "rD5NXb0lTivig10ZV3mxM9D8",
                      "team_id": "T016KDU3F5E",
                      "api_app_id": "A0170GC6P5F",
                      "event": {
                        "type": "message",
                        "subtype": "bot_message",
                        "text": "Getting data ...",
                        "ts": "1595410181.001200",
                        "bot_id": "B016XU7CMQD",
                        "channel": "C0174A9HH0A",
                        "event_ts": "1595410181.001200",
                        "channel_type": "channel"
                    },
                    "type": "event_callback",
                    "event_id": "Ev017B4UMHPG",
                    "event_time": 1595410181,
                    "authed_users": ["U016MTEJHSN"]
                };
                try {
                    const response = await axios.post('/api/slack/events', message);

                    console.log(response.data);
                } catch (res) {
                    console.log(res.response.data);
                }
            },
        },
        created() {
            this.LIST_USERS();
        }
    }
</script>
<style scoped lang="scss">
    .form-body {}
    .portlet.light.bordered {
        border: 1px solid #e7ecf1 !important;
        /*width: 800px;*/
        /*margin: 0 auto;*/
    }

    .portlet.light.bordered > .portlet-title {
        border-bottom: 1px solid #eef1f5;
    }

    .portlet.light > .portlet-title > .caption {
        color: #666;
        padding: 10px 0;
        padding-left: 20px;
    }

    .portlet > .portlet-title > .caption {
        font-size: 18px;
        line-height: 18px;
        padding: 10px 0;
    }

    .portlet.light > .portlet-title > .caption > .caption-subject {
        font-size: 16px;
        font-weight: bold;
    }

    .font-dark {
        color: #2f353b !important;
    }

    .uppercase {
        text-transform: uppercase !important;
    }

    .form .form-body, .portlet-form .form-body {
        padding: 20px;
    }

    .help-block {
        margin-top: 5px;
        margin-bottom: 5px;
        display: block;
        color: #737373;
    }

    .btn.blue {
        color: #fff;
        background-color: #3598dc;
        border-color: #3598dc;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        outline: none;
    }
    .btn.gray {
        border: 1px solid #ced4da;
        color: #333;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        outline: none;
    }
    .portlet.light > .portlet-title > .caption {
        background-color: #0660a0;
        border-color: #0660a0;
        color: #ffffff;
    }
    .input-group-btn {
        width: 100%;
        button {
            width: 100%;
        }
    }
    .btn-plus {
        display: flex;
        justify-content: flex-end;
    }
    .btn-danger {
        border-radius: 0;
    }
    .circle {
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
    }
    .submit {
        width: 100px;
        background-color: #3598dc;
    }
    .bold {
        font-weight: bold;
    }

    .form-group {
        &.row {
            display: flex;
            align-items: flex-start;
        }
    }
    .error2 {
        text-align: center;
        padding-top: 20px;
    }
</style>
