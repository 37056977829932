var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("admin-header"),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("div", { attrs: { id: "Activity-content" } }, [
          _c("div", { attrs: { id: "content" } }, [
            _c("div", { staticClass: "row my-3 user-prof-img" }, [
              _c("div", { staticClass: "col-auto mx-auto" }, [
                _vm.userInfo
                  ? _c("img", {
                      staticClass: "circle main-avatar",
                      staticStyle: { width: "128px" },
                      attrs: {
                        src: _vm.userInfo.image,
                        onerror: "this.src='/images/generic_avatar.png'",
                        alt: _vm.userInfo.real_name
                      }
                    })
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "user-prof-id" }, [
              _c("div", { staticClass: "row my-3 user-prof-b1" }, [
                _c("div", { staticClass: "col-auto mx-auto" }, [
                  _c("span", [_vm._v(_vm._s(_vm.userInfo.real_name))])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row my-3 user-prof-b2" }, [
                _c("div", {}, [
                  _c("div", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.userInfo.given))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-center" }, [
                    _vm._v("もらった")
                  ])
                ]),
                _vm._v(" "),
                _c("div", {}, [
                  _c("div", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.userInfo.receive))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-center" }, [_vm._v("あげた")])
                ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "search-result", attrs: { id: "search_result" } },
              [
                _c("div", {}, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-wrap justify-content-between full"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "alert alert-info col-12 col-md-9 col-lg-10"
                        },
                        [
                          _vm.activity && _vm.activity.total
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.activity.total) +
                                    " 件中 " +
                                    _vm._s(
                                      _vm.activity.per_page * (_vm.page - 1) + 1
                                    ) +
                                    " ～ " +
                                    _vm._s(
                                      _vm.activity.per_page * _vm.page <=
                                        _vm.activity.total
                                        ? _vm.activity.per_page * _vm.page
                                        : _vm.activity.total
                                    ) +
                                    " 件目を表示しています。"
                                )
                              ])
                            : _c("span", [_vm._v("データがありません。")])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 col-md-3 col-lg-2 text-right date-block ex mt-4 mt-md-0"
                        },
                        [
                          _c(
                            "b-dropdown",
                            {
                              attrs: { left: "", text: "あげた" },
                              scopedSlots: _vm._u([
                                {
                                  key: "button-content",
                                  fn: function() {
                                    return [
                                      _vm.type == 1
                                        ? _c("span", [_vm._v("もらった")])
                                        : _c("span", [_vm._v("あげた")]),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "ml-1" }, [
                                        _c("img", {
                                          attrs: {
                                            width: "25px",
                                            src: "/images/heart.png",
                                            alt: ""
                                          }
                                        })
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "javascript:;" },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeType(1)
                                    }
                                  }
                                },
                                [
                                  _vm._v("もらった "),
                                  _c("span", { staticClass: "ml-1" }, [
                                    _c("img", {
                                      attrs: {
                                        width: "25px",
                                        src: "/images/heart.png",
                                        alt: ""
                                      }
                                    })
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-dropdown-item",
                                {
                                  attrs: { href: "javascript:;" },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeType(2)
                                    }
                                  }
                                },
                                [
                                  _vm._v("あげた "),
                                  _c("span", { staticClass: "ml-1" }, [
                                    _c("img", {
                                      attrs: {
                                        width: "25px",
                                        src: "/images/heart.png",
                                        alt: ""
                                      }
                                    })
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "activity_index" }, [
                  _vm.activity &&
                  _vm.activity.data &&
                  _vm.activity.data.length > 0
                    ? _c(
                        "div",
                        _vm._l(_vm.activity.data, function(act, i) {
                          return _c("div", { staticClass: "row mb-4 mx-1" }, [
                            act.type == 1
                              ? _c(
                                  "div",
                                  { staticClass: "col-auto acty-info-a" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:;" },
                                        on: {
                                          click: function($event) {
                                            _vm.user.is_admin
                                              ? _vm.changeUser(act.send_user.id)
                                              : ""
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "48px",
                                            "margin-right": "10px"
                                          },
                                          attrs: {
                                            src: act.send_user.image,
                                            onerror:
                                              "this.src='/images/generic_avatar.png'",
                                            alt: act.send_user.real_name
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            act.type == 1
                              ? _c(
                                  "div",
                                  { staticClass: "col-auto acty-info-b" },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("datetimeFormat")(
                                            act.created_at
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "/to\n                                        "
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:;" },
                                          on: {
                                            click: function($event) {
                                              _vm.user.is_admin
                                                ? _vm.changeUser(
                                                    act.receive_user.id
                                                  )
                                                : ""
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "@" +
                                              _vm._s(act.receive_user.real_name)
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(act.message) +
                                          "\n                                    "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            act.type == 2
                              ? _c(
                                  "div",
                                  { staticClass: "col-auto acty-info-a" },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:;" },
                                        on: {
                                          click: function($event) {
                                            _vm.user.is_admin
                                              ? _vm.changeUser(act.send_user.id)
                                              : ""
                                          }
                                        }
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "48px",
                                            "margin-right": "10px"
                                          },
                                          attrs: {
                                            src: act.send_user.image,
                                            onerror:
                                              "this.src='/images/generic_avatar.png'",
                                            alt: act.receive_user.real_name
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            act.type == 2
                              ? _c(
                                  "div",
                                  { staticClass: "col-auto acty-info-b" },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("datetimeFormat")(
                                            act.created_at
                                          )
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "/from\n                                        "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "javascript:;" },
                                          on: {
                                            click: function($event) {
                                              _vm.user.is_admin
                                                ? act.receive_user.id
                                                : ""
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "@" +
                                              _vm._s(
                                                act.receive_user.real_name
                                              ) +
                                              "\n                                        "
                                          )
                                        ]
                                      ),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(act.message) +
                                          "\n                                    "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ])
                        }),
                        0
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.activity
                  ? _c(
                      "div",
                      { staticClass: "padding-all" },
                      [
                        _vm.activity.last_page > 1
                          ? _c("pagination", {
                              attrs: {
                                "current-page": _vm.activity.current_page,
                                "per-page": _vm.activity.per_page,
                                total: _vm.activity.total
                              },
                              on: { changePage: _vm.changePage }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          ]),
          _vm._v(" "),
          _vm._m(0)
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "messageModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "messageModalTitle",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title",
                    attrs: { id: "messageModalTitle" }
                  },
                  [_vm._v(" ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-label": "Close"
                    }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("p", { attrs: { id: "messageModalBody" } })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" }
                  },
                  [_vm._v("閉じる")]
                )
              ])
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }