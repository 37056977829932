<template>
    <div>
        <admin-header></admin-header>
        <div class="container">
            <div id="Activity-content">
                <div id="content">
                    <div class="row my-3 user-prof-img">
                        <div class="col-auto mx-auto">
                            <img v-if="userInfo" class="circle main-avatar" style="width: 128px;" :src="userInfo.image"
                                 onerror="this.src='/images/generic_avatar.png'" :alt="userInfo.real_name">
                        </div>
                    </div>
                    <div class="user-prof-id">
                        <div class="row my-3 user-prof-b1">
                            <div class="col-auto mx-auto">
                                <span>{{ userInfo.real_name }}</span>
                            </div>
                        </div>
                        <div class="row my-3 user-prof-b2">
                            <div class="">
                                <div class="text-center">{{ userInfo.given }}</div>
                                <div class="text-center">もらった</div>
                            </div>
                            <div class="">
                                <div class="text-center">{{ userInfo.receive }}</div>
                                <div class="text-center">あげた</div>
                            </div>
                            <!--<div class="col-auto mr-auto"></div>-->
                        </div>
                    </div>
                    <div id="search_result" class="search-result">
                        <div class="">
                            <div class="d-flex flex-wrap justify-content-between full">
                                <div class="alert alert-info col-12 col-md-9 col-lg-10">
                                    <span v-if="activity && activity.total">{{ activity.total }} 件中 {{ activity.per_page * (page - 1) + 1}} ～ {{ activity.per_page * page <= activity.total ? activity.per_page * page : activity.total }} 件目を表示しています。</span>
                                    <span v-else>データがありません。</span>
                                </div>
                                <div class="col-12 col-md-3 col-lg-2 text-right date-block ex mt-4 mt-md-0">
                                    <b-dropdown left text="あげた">
                                        <template v-slot:button-content>
                                            <span v-if="type == 1">もらった</span>
                                            <span v-else>あげた</span>
                                            <span class="ml-1"><img width="25px" src="/images/heart.png"
                                                                    alt=""></span>
                                        </template>
                                        <b-dropdown-item href="javascript:;" @click="changeType(1)">もらった <span
                                            class="ml-1"><img width="25px" src="/images/heart.png" alt=""></span>
                                        </b-dropdown-item>
                                        <b-dropdown-item href="javascript:;" @click="changeType(2)">あげた <span
                                            class="ml-1"><img width="25px" src="/images/heart.png" alt=""></span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="activity_index">
                            <div v-if="activity && activity.data && activity.data.length > 0">
                                <div class="row mb-4 mx-1" v-for="(act, i) in activity.data">
                                    <div class="col-auto acty-info-a" v-if="act.type == 1">
                                        <!--<router-link :to="{name: 'AdminUserDetail', params: {id: act.send_user.id}}">-->
                                        <a href="javascript:;" @click="user.is_admin ? changeUser(act.send_user.id) : ''">
                                            <img style="width: 48px;margin-right: 10px;" :src="act.send_user.image"
                                                 onerror="this.src='/images/generic_avatar.png'"
                                                 :alt="act.send_user.real_name">
                                        </a>
                                        <!--</router-link>-->
                                    </div>
                                    <div class="col-auto acty-info-b" v-if="act.type == 1">
                                        <div>{{ act.created_at | datetimeFormat }}</div>
                                        <div>/to
                                            <!--<router-link-->
                                                <!--:to="{name: 'AdminUserDetail', params: {id: act.receive_user.id}}">@{{-->
                                                <!--act.receive_user.real_name }}-->
                                            <!--</router-link>-->
                                            <a href="javascript:;" @click="user.is_admin ? changeUser(act.receive_user.id) : ''">@{{
                                                act.receive_user.real_name }}</a>
                                            {{ act.message }}
                                        </div>
                                    </div>

                                    <div class="col-auto acty-info-a" v-if="act.type == 2">
                                        <!--<router-link :to="{name: 'AdminUserDetail', params: {id: act.receive_user.id}}">-->
                                        <a href="javascript:;" @click="user.is_admin ? changeUser(act.send_user.id) : ''">
                                            <img style="width: 48px;margin-right: 10px;" :src="act.send_user.image"
                                                 onerror="this.src='/images/generic_avatar.png'"
                                                 :alt="act.receive_user.real_name">
                                        </a>
                                        <!--</router-link>-->
                                    </div>
                                    <div class="col-auto acty-info-b" v-if="act.type == 2">
                                        <div>{{ act.created_at | datetimeFormat }}</div>
                                        <div>/from
                                            <a href="javascript:;" @click="user.is_admin ? (act.receive_user.id) : ''">@{{
                                                act.receive_user.real_name }}
                                            </a>
                                            {{ act.message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="padding-all" v-if="activity">
                            <pagination :current-page="activity.current_page"
                                        :per-page="activity.per_page"
                                        :total="activity.total"
                                        @changePage="changePage"
                                        v-if="activity.last_page > 1"
                            ></pagination>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="messageModal" tabindex="-1" role="dialog" aria-labelledby="messageModalTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="messageModalTitle">&nbsp;</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p id="messageModalBody"></p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<admin-footer></admin-footer>-->
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    export default {
        data() {
            return {
                activity: null,
                userInfo: {},
                type: 1,
                page: 1
            }
        },
        computed: {
          ...mapState('user', ['user'])
        },
        watch: {
          'user.id': function () {
              if (this.user.id != this.$route.params.id && !this.user.is_admin) {
                  this.$router.push({
                      name: 'AdminUserDetail',
                      params: {
                          id: this.user.id
                      }
                  })
              }
          }
        },
        methods: {
            changeType(type) {
                this.activity = null
                this.page = 1
                this.type = type
                if (this.$route.query.type !== type) {
                    this.getData();
                    this.$router.push({
                        name: 'AdminUserDetail',
                        params: {
                            id: this.$route.params.id
                        },
                        query: {
                            type: type
                        }
                    })
                }
            },
            changePage(page) {
                this.page = page
                this.refreshList()
            },
            async refreshList() {
                this.getData(this.page)
                this.$router.push({
                    name: 'AdminUserDetail', params: {
                        id: this.$route.params.id
                    },
                    query: {
                        type: this.type,
                        page: this.page
                    }
                });
            },
            async getData() {
                try {
                    let {data} = await axios.get('/api/redemptions/' + this.$route.params.id + '?type=' + this.type + '&page=' + this.page)

                    this.userInfo = data.user
                    this.activity = data.activity
                } catch (e) {
                    console.log(e)

                }
            },
            changeUser(id) {
                if (this.$route.params.id != id) {
                    this.$router.push({name: 'AdminUserDetail', params: {id: id}})
                    this.getData()
                }
            }
        },
        created() {
            this.activity = null
            if (this.$route.query.type) {
                this.type = this.$route.query.type
            }
            if (this.$route.query.page) {
                this.page = this.$route.query.page
            }
            this.getData()
        }
    };
</script>
<style scoped lang="scss">
    .user-prof-b2 {
        display: flex;
        justify-content: center;
        div {
            padding: 0 15px;
        }
    }
    .search-result {
        margin-top: -9em;
    }
</style>

