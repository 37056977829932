import Cookies from 'js-cookie'
import axios from "axios";

export default {
    namespaced: true,
    state: {
        token: Cookies.get('user-token') || '',
        status: '',
        user: {},
        users: []
    },
    getters: {
        user: state => state.user,
        isAuthenticated: state => !!state.token,
    },
    mutations: {
        AUTH_REQUEST(state) {
            state.status = 'loading'
        },
        AUTH_SUCCESS(state, token) {
            state.status = 'success'
            state.token = token
        },
        AUTH_ERROR(state) {
            state.status = 'error'
        },
        AUTH_USER(state, user) {
            state.user = user
        },
        SET_USERS(state, users) {
            state.users = users
        }
    },
    actions: {
        AUTH_REQUEST({commit, dispatch, state}, user) {
            return new Promise((resolve, reject) => {
                // The Promise used for router redirect in login
                axios({url: '/api/users/create', data: user, method: 'POST'})
                    .then(resp => {
                        console.log('resp ------------')
                        console.log(resp)
                        let token = resp.data.token
                        const user = resp.data.user
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                        Cookies.set('user-token', token)
                        commit('AUTH_SUCCESS', token)
                        commit('AUTH_USER', user)
                        resolve(resp)
                    })
                    .catch(err => {
                        console.log('er ------------')
                        console.log(err)
                        commit('AUTH_ERROR', err)
                        Cookies.remove('user-token') // if the request fails, remove any possible user token if possible
                        reject(err)
                    })
            })
        },

        async AUTH_TOKEN({commit, dispatch, getters}) {
            try {
                let {data} = await axios.get('/api/users/info')
                let user = data
                commit('AUTH_USER', user)
                return user
            } catch (e) {
                dispatch('AUTH_LOGOUT')
                commit('AUTH_SUCCESS', '')
                return e.response
            }
        },


        async AUTH_LOGOUT({commit, dispatch, getters}) {
            try {
                let res = await axios.post('/api/users/logout')
                commit('AUTH_SUCCESS', '')
                Cookies.remove('user-token')
                return res
            } catch (e) {
                return e
            }
        },


        async LIST_USERS({commit}) {
            try {
                let { data } = await axios.get('/api/users')
                commit('SET_USERS', data)
            } catch (e) {
                return e
            }
        },


        SET_USER({commit}, user) {
            commit('AUTH_USER', user)
        }
    }
}
