import VueRouter from 'vue-router';
import NotFound from './../components/404.vue';
import Home from './../components/Home.vue';
import AdminIndex from './../components/admin/Index.vue';
import AdminUser from './../components/admin/User.vue';
import AdminUserDetail from './../components/admin/UserDetail.vue';
import AdminRanking from './../components/admin/Ranking.vue';
import AdminChannel from './../components/admin/Channel.vue';
import AdminSetting from './../components/admin/Setting.vue';
import AdminAdjust from './../components/admin/Adjust.vue';
import AdminForm from './../components/admin/Form.vue';
import AdminWorksheet from './../components/admin/Worksheet.vue';
import store from '../store'

const ifAuthenticated = (to, from, next) => {
    store.watch(
        (state) => {
            if (!!state.user.token) {
                if (_.isEmpty(store.getters['user/user'])) {
                    store.dispatch('user/AUTH_TOKEN').then((data) => {
                        if(data.id) {
                            if ((to.name == 'AdminUser' || to.name == 'AdminSetting' || to.name == 'AdminAdjust' || to.name == 'AdminChannel' || to.name == 'AdminRanking') && !data.is_admin) {
                                next('/admin')
                            }
                            next()
                        } else {
                            next('/')
                        }
                    }).catch((e) => {
                        next('/')
                    })
                }
                next()
                return
            }
            next('/')
        }
    )
}

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters['user/isAuthenticated'] || to.params.code !== '') {
        next()
        return
    }
    next('/admin')
}


const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        beforeEnter: ifNotAuthenticated,
        meta: {
            title: '一日一善システム'
        }
    },
    // {
    //     path: '/admin',
    //     name: 'AdminIndex',
    //     component: AdminIndex,
    //     beforeEnter: authenReditect,
    //     meta: {
    //     }
    // },
    {
        path: '/admin/users',
        name: 'AdminUser',
        component: AdminUser,
        beforeEnter: ifAuthenticated,
        meta: {
            title: '一日一善システム'
        }
    },
    {
        path: '/admin/users/:id',
        name: 'AdminUserDetail',
        component: AdminUserDetail,
        beforeEnter: ifAuthenticated,
        meta: {
            title: '一日一善システム'
        }
    },
    {
        path: '/admin/ranking',
        name: 'AdminRanking',
        component: AdminRanking,
        beforeEnter: ifAuthenticated,
        meta: {
            title: '一日一善システム'
        }
    },
    {
        path: '/admin/activity',
        name: 'AdminChannel',
        component: AdminChannel,
        beforeEnter: ifAuthenticated,
        meta: {
            title: '一日一善システム'
        }
    },
    {
        path: '/admin/settings',
        name: 'AdminSetting',
        component: AdminSetting,
        beforeEnter: ifAuthenticated,
        meta: {
            title: '一日一善システム'
        }
    },
    {
        path: '/admin/adjust',
        name: 'AdminAdjust',
        component: AdminAdjust,
        beforeEnter: ifAuthenticated,
        meta: {
            title: '一日一善システム'
        }
    },
    {
        path: '/admin',
        name: 'AdminForm',
        component: AdminForm,
        beforeEnter: ifAuthenticated,
        meta: {
            title: '一日一善システム'
        }
    },
    {
        path: '/admin/worksheet',
        name: 'AdminWorksheet',
        component: AdminWorksheet,
        beforeEnter: ifAuthenticated,
        meta: {
            title: '一日一善システム'
        }
    },
]


const router = new VueRouter({
    mode: 'history',
    routes: routes
});

export default router
