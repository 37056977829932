<template>
    <div class="signin-body">
        <div>
            <h5 class="title">一日一善システム</h5>
            <!--<a class="btn btn-primary signin"-->
               <!--:href="'https://slack.com/oauth/v2/authorize?client_id='+ MIX_SLACK_CLIENT_ID + '&scope=channels:history,channels:read,team:read,users:read,users:read.email,chat:write,im:history,app_mentions:read,groups:history,mpim:history,channels:read,groups:read,mpim:read,im:read,chat:write&user_scope=identity.basic,identity.avatar,identity.email'">-->
                <!--<i data-v-3038f62f="" class="fab fa-slack"></i> Slackでログイン-->
            <!--</a>-->

            <a class="btn btn-primary signin"
               :href="'https://slack.com/oauth/v2/authorize?client_id='+ MIX_SLACK_CLIENT_ID + '&user_scope=identity.basic,identity.avatar,identity.email,identity.team&redirect_uri=' + MIX_SLACK_REDIRECT_URI">
                <i data-v-3038f62f="" class="fab fa-slack"></i> Slackでログイン
            </a>
            <!--<button class="btn btn-dark" @click="login()">Login</button>-->
        </div>
        <!--<br>-->
        <!--Goodeed3-->
        <!--<a :href="'https://slack.com/oauth/v2/authorize?client_id='+ MIX_SLACK_CLIENT_ID + '&scope=groups:history,groups:read,channels:history,channels:read,team:read,users:read,users:read.email,chat:write,im:history,mpim:history&redirect_uri=' + MIX_SLACK_REDIRECT_URI"><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a>-->
    </div>
</template>

<script>
    export default {
        data: function() {
            return {
                MIX_SLACK_CLIENT_ID: process.env.MIX_SLACK_CLIENT_ID,
                MIX_SLACK_CLIENT_SECRET: process.env.MIX_SLACK_CLIENT_SECRET,
                MIX_SLACK_REDIRECT_URI: process.env.MIX_SLACK_REDIRECT_URI,
            }
        },
        async created() {
            let code = this.$route.query.code
            console.log(code)
            if (!_.isEmpty(code)) {
                this.$wait.start('loading')
                try {
                    let { data } = await axios.get('https://slack.com/api/oauth.v2.access', { params: {
                        'client_id': process.env.MIX_SLACK_CLIENT_ID,
                        'client_secret': process.env.MIX_SLACK_CLIENT_SECRET,
                        'redirect_uri': process.env.MIX_SLACK_REDIRECT_URI,
                        'code': code
                    }})

                    console.log('oauth info ============')
                    console.log(data)

                    let user = await axios.get('https://slack.com/api/users.identity', { params: {
                        'token': data.authed_user.access_token
                    }})

                    // if (data.app_id) {
                    //     let team = await axios.post('/api/team/create', data)
                    //
                    //     console.log(team.data.id)
                    //     if (team.data.id && !_.isEmpty(user.data.user)) {
                    //         user.data.user.team_id = team.data.id
                    //     }
                    //     console.log('team ==============')
                    //     console.log(team)
                    // }


                    if (!_.isEmpty(user.data.user)) {
                        if (data.authed_user.access_token) {
                            user.data.user.access_token = data.authed_user.access_token
                        }
                        console.log('identify info ============')
                        console.log(user)
                        this.slackLogin(user.data);
                    }

                    // console.log(info)
                } catch (e) {
                    console.log('&&&&&&')
                    console.log(e)
                }
            }

        },
        methods: {
            async slackLogin(user) {
                await this.$store.dispatch('user/AUTH_REQUEST', user).then(() => {
                    this.$router.push({
                        name: 'AdminForm'
                    })
                    this.$wait.end('loading')
                })
            },
            async login() {
                let data = {
                    user: {
                        email: 'thang.tsbd@gmail.com',
                        id: 'U015ES79VFH',
                        name: 'THANG NGUYEN',
                        image_512: 'https://avatars.slack-edge.com/2020-07-03/1227265237252_fe1ef429e396543b0d1c_512.jpg',
                        access_token: 'xoxp-1223470117184-1184891335527-1232680450758-172e06d0bab3dcee67c32aef32b2b601'
                    },
                    team: {
                        domain: 'thangsnguyen',
                        id: 'T016KDU3F5E',
                        image_230: "THANG'S NGUYEN",
                        name: 'https://a.slack-edge.com/80588/img/avatars-teams/ava_0004-230.png',
                    }
                }

                this.$store.dispatch('user/AUTH_REQUEST', data).then(() => {
                    this.$router.push({
                        name: 'AdminForm'
                    })
                })
                // let info = await axios.post('api/users/create', data)
            }
        }
    };
</script>
<style lang="scss" scoped>
    .signin-body {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
        background-color: #005591;

        .signin {
            border-color: #fff;
            width: 250px;
            text-align: center;
            background-color: transparent;
            text-transform: uppercase;
        }
    }
    .title {
        text-align: center;
        color: #ffffff;
        margin-bottom: 20px;
        font-size: 25px;
    }
</style>
