var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("admin-header"),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("div", { attrs: { id: "Admin-users" } }, [
          _c("div", { attrs: { id: "content" } }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { attrs: { id: "search_result" } }, [
              _c("div", [
                _c("div", { staticClass: "alert alert-info" }, [
                  _vm.users && _vm.users.total
                    ? _c("span", [
                        _vm._v(
                          _vm._s(_vm.users.total) +
                            " 件中 " +
                            _vm._s(_vm.users.per_page * (_vm.page - 1) + 1) +
                            " ～ " +
                            _vm._s(
                              _vm.users.per_page * _vm.page <= _vm.users.total
                                ? _vm.users.per_page * _vm.page
                                : _vm.users.total
                            ) +
                            " 件目を表示しています。"
                        )
                      ])
                    : _c("span", [_vm._v("データがありません。")])
                ])
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "table-responsive admin_users_index" }, [
                _c(
                  "table",
                  {
                    staticClass:
                      "table table-bordered table-striped table-hover"
                  },
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _vm.users && _vm.users.data
                      ? _c(
                          "tbody",
                          _vm._l(_vm.users.data, function(userInfo) {
                            return _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass: "person align-middle text-left"
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "AdminUserDetail",
                                          params: { id: userInfo.code }
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          width: "48px",
                                          "margin-right": "10px"
                                        },
                                        attrs: {
                                          src: userInfo.image,
                                          onerror:
                                            "this.src='/images/generic_avatar.png'",
                                          alt: "BSV Vu Truong"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(userInfo.label))
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "total align-middle text-center role"
                                },
                                [
                                  !userInfo.is_owner
                                    ? _c(
                                        "b-dropdown",
                                        {
                                          staticClass: "m-2",
                                          attrs: {
                                            id: "dropdown-right",
                                            text: userInfo.is_admin
                                              ? "管理ユーザー"
                                              : "一般ユーザー",
                                            variant: "primary"
                                          }
                                        },
                                        [
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: { href: "javascript:;" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.changeRole(
                                                    userInfo.code,
                                                    1
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("管理ユーザー")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-dropdown-item",
                                            {
                                              attrs: { href: "javascript:;" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.changeRole(
                                                    userInfo.code,
                                                    0
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("一般ユーザー")]
                                          )
                                        ],
                                        1
                                      )
                                    : _c("span", [_vm._v("管理ユーザー")])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "total align-middle text-center"
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.updateUser(userInfo.user)
                                        }
                                      }
                                    },
                                    [_vm._v("⟳")]
                                  )
                                ]
                              )
                            ])
                          }),
                          0
                        )
                      : _vm._e()
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.users
                ? _c(
                    "div",
                    { staticClass: "padding-all" },
                    [
                      _vm.users.last_page > 1
                        ? _c("pagination", {
                            attrs: {
                              "current-page": _vm.users.current_page,
                              "per-page": _vm.users.per_page,
                              total: _vm.users.total
                            },
                            on: { changePage: _vm.changePage }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _vm._m(4)
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row my-3" }, [
      _c("div", { staticClass: "col-auto" }, [
        _c("span", { staticClass: "h4" }, [
          _c("i", { staticClass: "fas fa-user-cog" }),
          _vm._v("ユーザー")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "form",
      {
        attrs: {
          id: "form_update_auth",
          method: "post",
          action: "./ajax_update_auth.php"
        }
      },
      [
        _c("input", {
          staticClass: "token",
          attrs: {
            type: "hidden",
            name: "token",
            value: "jsXGkAwr2guQ8DUGZgTBOoxRZNsiOWQHJOCJAbA5"
          }
        }),
        _vm._v(" "),
        _c("input", {
          staticClass: "user_id",
          attrs: { type: "hidden", name: "user_id", value: "" }
        }),
        _vm._v(" "),
        _c("input", {
          staticClass: "is_app_admin",
          attrs: { type: "hidden", name: "is_app_admin", value: "" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "form",
      {
        attrs: {
          id: "form_sync_slack",
          method: "post",
          action: "./ajax_sync_slack.php"
        }
      },
      [
        _c("input", {
          staticClass: "token",
          attrs: {
            type: "hidden",
            name: "token",
            value: "mkRx9AfZlZcTLpkgMo3yU8qiEXZOxalpBHWN9IBi"
          }
        }),
        _vm._v(" "),
        _c("input", {
          staticClass: "user_id",
          attrs: { type: "hidden", name: "user_id", value: "" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "thead-dark" }, [
      _c("tr", [
        _c("th", { staticClass: "person text-center" }, [_vm._v("ユーザー")]),
        _vm._v(" "),
        _c("th", { staticClass: "is_app_admin text-center" }, [_vm._v("権限")]),
        _vm._v(" "),
        _c("th", { staticClass: "sync text-center" }, [_vm._v("同期")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "messageModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "messageModalTitle",
          "aria-hidden": "true"
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title",
                    attrs: { id: "messageModalTitle" }
                  },
                  [_vm._v(" ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-label": "Close"
                    }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×")
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("p", { attrs: { id: "messageModalBody" } })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" }
                  },
                  [_vm._v("閉じる")]
                )
              ])
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }