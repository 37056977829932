import axios from 'axios'
import Cookies from "js-cookie";
import router from "./router"
import store from './store'
import _ from 'lodash'

// if (!_.isUndefined(Cookies.get('user-token'))) {
//     axios.defaults.headers.common['Authorization'] = 'Bearer ' + Cookies.get('user-token');
// }

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    if (!config.url.includes("https://slack.com/api") && !_.isUndefined(Cookies.get('user-token'))) {
        config.headers['Authorization'] = 'Bearer ' + Cookies.get('user-token');
    }
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});


// Add a response interceptor
axios.interceptors.response.use( (response) => {
    // Do something with response data
    return response;
}, (error) => {
    console.log(error.response.data.code)
    if (error.response.data.code && error.response.data.code == 401 ) {
        let token = Cookies.get('user-token')
        if (!_.isEmpty(token)) {
            Cookies.remove('user-token')
            // store.dispatch('user/AUTH_LOGOUT').then(() => {
                router.push({
                    name: 'Home'
                })
            // })
        }
    }
    return Promise.reject(error);
});

export default axios;
