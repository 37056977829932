<template>
    <div class="body">
        <v-wait for="loading">
            <template slot="waiting">
                <div>
                    <div class="wrap-loading"></div>
                    <div class="v-spinner">
                        <div class="loadingio-spinner-rolling-2n8ectvu1hk"><div class="ldio-gf8wf4cp9ou">
                            <div></div>
                        </div></div>
                    </div>
                </div>
            </template>
        </v-wait>
        <router-view></router-view>
    </div>
</template>
<script>
    export default {
        data: function () {
            return {
            }
        },
        created() {

        },
        computed: {
        },
        mounted() {
        },
        methods: {
        },
        beforeCreate() {
        },
        beforeRouteEnter(to, from, next) {
        },
        beforeRouteUpdate(to, from, next) {
        }
    }
</script>
<style lang="scss" scoped>

</style>
