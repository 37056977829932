import Vue from 'vue';
import moment from 'moment'

Vue.filter('numberFormat', function (num) {
    if (isNaN(num)) return 0;
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
});

Vue.filter('dateFormat', function (date) {
    return moment(date).isValid() ? moment(date).format("YYYY年MM月DD日") : '';
});

Vue.filter('datetimeFormat', function (date) {
    return moment(date).isValid() ? moment(date).format("YYYY年MM月DD日 HH:mm") : '';
});
