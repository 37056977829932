<template>
    <div>
        <admin-header></admin-header>
        <div class="container">
            <div id="Activity-content">
                <div id="content">
                    <div class="row my-3 tittle-area-p">
                        <div class="col-auto tittle-area"><img src="/images/activity_icon.png" alt="ランキング"><span class="h4">- アクティビティ -</span></div>
                    </div>
                    <div id="search_result">
                        <div class="row">
                            <div class="alert alert-info col-12 col-md-9 col-lg-10">
                                <span v-if="activity && activity.total">{{ activity.total }} 件中 {{ activity.per_page * (page - 1) + 1}} ～ {{ activity.per_page * page <= activity.total ? activity.per_page * page : activity.total }} 件目を表示しています。</span>
                                <span v-else>データがありません。</span>
                            </div>
                            <div class="col-12 col-md-3 col-lg-2 text-right date-block">
                                <select name="" id="" class="form-control" v-model="month" @change="changeMonth()">
                                    <option v-for="i in months()">{{ i }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="activity_index" v-if="activity && activity.data && activity.data.length > 0">
                            <div>
                                <div class="row mb-4 mx-1" v-for="(act, i) in activity.data">
                                    <div class="col-auto acty-info-a" v-if="act.type == 1">
                                        <router-link :to="{name: 'AdminUserDetail', params: {id: act.send_user.id}}">
                                            <img style="width: 48px;margin-right: 10px;" :src="act.send_user.image"
                                                 onerror="this.src='/images/generic_avatar.png'"
                                                 :alt="act.send_user.real_name">
                                            <span class="left-20">{{ act.send_user.real_name }}</span>
                                        </router-link>
                                        <span class="dn-mar-pc only-pc"><i class='fas fa-angle-double-right'></i></span>
                                        <router-link :to="{name: 'AdminUserDetail', params: {id: act.receive_user.id}}">
                                            <img style="width: 48px;margin-right: 10px;" :src="act.receive_user.image"
                                                 onerror="this.src='/images/generic_avatar.png'"
                                                 :alt="act.receive_user.real_name">
                                            <span class="left-20">{{ act.receive_user.real_name }}</span>
                                        </router-link>
                                    </div>
                                    <div class="col-auto acty-info-b" v-if="act.type == 1">
                                        <div>{{ act.created_at | datetimeFormat }}</div>
                                        <div>/to
                                            <router-link
                                                :to="{name: 'AdminUserDetail', params: {id: act.receive_user.id}}">@{{
                                                act.receive_user.real_name }}
                                            </router-link>
                                            {{ act.message }}
                                        </div>
                                    </div>
                                    <div class="col-auto acty-info-a" v-if="act.type == 2">
                                        <router-link :to="{name: 'AdminUserDetail', params: {id: act.send_user.id}}">
                                            <img style="width: 48px;margin-right: 10px;" :src="act.send_user.image"
                                                 onerror="this.src='/images/generic_avatar.png'"
                                                 :alt="act.send_user.real_name">
                                            <span class="left-20">{{ act.send_user.real_name }}</span>
                                        </router-link>
                                        <span class="dn-mar-pc only-pc"><i class='fas fa-angle-double-right'></i></span>
                                        <router-link :to="{name: 'AdminUserDetail', params: {id: act.receive_user.id}}">
                                            <img style="width: 48px;margin-right: 10px;" :src="act.receive_user.image"
                                                 onerror="this.src='/images/generic_avatar.png'"
                                                 :alt="act.receive_user.real_name">
                                            <span class="left-20">{{ act.receive_user.real_name }}</span>
                                        </router-link>
                                    </div>
                                    <div class="col-auto acty-info-b" v-if="act.type == 2">
                                        <div>{{ act.created_at | datetimeFormat }}</div>
                                        <div>/from
                                            <router-link
                                                :to="{name: 'AdminUserDetail', params: {id: act.receive_user.id}}">@{{
                                                act.receive_user.real_name }}
                                            </router-link>
                                            {{ act.message }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="padding-all" v-if="activity">
                            <pagination :current-page="activity.current_page"
                                        :per-page="activity.per_page"
                                        :total="activity.total"
                                        @changePage="changePage"
                                        v-if="activity.last_page > 1"
                            ></pagination>
                        </div>
                    </div>
                </div>
                <div class="modal fade" id="messageModal" tabindex="-1" role="dialog" aria-labelledby="messageModalTitle" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="messageModalTitle">&nbsp;</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p id="messageModalBody"></p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">閉じる</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<admin-footer></admin-footer>-->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                activity: null,
                page: 1,
                month: this.currentMonth()
            }
        },
        methods: {
            currentMonth() {
                let d = new Date();
                return d.getFullYear()+'/'+((d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : d.getMonth() + 1)
            },
            months() {
                let dates = []
                const today = new Date();
                let d;

                for(let i = 0; i <= 6; i ++) {
                    d = new Date(today.getFullYear(), today.getMonth() - i, 1);
                    let newM = (d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : d.getMonth() + 1
                    let newY = d.getFullYear()
                    dates.push(newY+'/'+newM)
                }
                return dates
            },
            changePage(page) {
                this.page = page
                this.refreshList()
            },
            async changeMonth() {
                try {
                    let {data} = await axios.get('/api/redemptions?page=' + this.page+'&month='+this.month)
                    this.activity = data
                } catch (e) {

                }
            },
            async refreshList() {
                try {
                    let {data} = await axios.get('/api/redemptions?page=' + this.page+'&month='+this.month)
                    this.activity = data

                    this.$router.push({name: 'AdminChannel', query: {page: this.page, month: this.month }});
                } catch (e) {

                }
            },
        },
        async created() {
            try {
                let {data} = await axios.get('/api/redemptions?page=' + this.page+'&month='+this.month)
                this.activity = data
                console.log(this.activity)
            } catch (e) {

            }

        }
    };
</script>
<style scoped lang="scss">
    .padding-all {
        padding: 0 15px;
    }
    #Activity-content .acty-info-a span.left-20 {
        margin-left: -25px !important;
        width: 100px;
        display: block;
        text-align: center;

        @media screen and (max-width: 575px) {
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }
</style>

