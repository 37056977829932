<template>
    <div>
        <footer>
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <a href="/howto/">使い方</a>
                    </div>
                    <div class="col-auto">
                        <a href="/terms/">利用規約</a>
                    </div>
                    <div class="col-auto">
                        <a href="/privacy/">プライバシーポリシー</a>
                    </div>
                    <div class="col-auto">
                        <a href="/contact/">問い合わせ</a>
                    </div>
                </div>
                <p class="text-center">&copy; 2020 Faith Solution Technologies.</p>
            </div>
        </footer>
    </div>
</template>

<script>
    export default {
    };
</script>

