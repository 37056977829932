import 'babel-polyfill'
require('./bootstrap');
import 'es6-promise/auto'
import Vue from 'vue';
import router from './router';
import App from './components/App'
import store from './store';
import VueRouter from 'vue-router';
import VueWait from 'vue-wait';
import "./vee-validate";
import "./filter"
import AxiosPlugin from 'vue-axios-cors';
import Pagination from './components/Pagination.vue';
import { BootstrapVue } from 'bootstrap-vue'
import AdminHeader from './components/admin/Header.vue';
import AdminFooter from './components/admin/Footer.vue';
import 'vue-select/dist/vue-select.css';
import VueAxios from 'vue-axios';
import axios from './axios';
import {setInteractionMode, ValidationObserver, ValidationProvider} from 'vee-validate';
setInteractionMode('eager');

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('pagination', Pagination);
Vue.use(BootstrapVue)
Vue.use(VueWait);
Vue.router = router;
App.router = Vue.router;
Vue.use(VueRouter);

Vue.component('admin-header', AdminHeader);
Vue.component('admin-footer', AdminFooter);
Vue.use(VueAxios, axios);
Vue.use(AxiosPlugin)
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
Vue.use(datePicker);

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
    icons: {
        time: 'far fa-clock',
        date: 'far fa-calendar',
        up: 'fas fa-arrow-up',
        down: 'fas fa-arrow-down',
        previous: 'fas fa-chevron-left',
        next: 'fas fa-chevron-right',
        today: 'fas fa-calendar-check',
        clear: 'far fa-trash-alt',
        close: 'far fa-times-circle'
    }
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if(!nearestWithMeta) return next();

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which meta tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
    // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});

const wait = new VueWait();

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  wait.start('loading');
  
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use((response) => {
  // Do something with response data
  wait.end('loading');
  return response;
}, (error) => {
  wait.end('loading');
  // Do something with response error
  return Promise.reject(error);
});

const app = new Vue({
    store,
    router,
    wait: wait,
    render: h => h(App),
    methods: {}
}).$mount('#app');

window.app = app;
