var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [_c("admin-header"), _vm._v(" "), _vm._m(0)], 1)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("div", { attrs: { id: "Admin-settings" } }, [
        _c("div", { attrs: { id: "content" } }, [
          _c("div", { staticClass: "row my-3" }, [
            _c("div", { staticClass: "col-auto" }, [
              _c("span", { staticClass: "h4" }, [_vm._v("設定")])
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "form",
              {
                attrs: {
                  id: "form_update",
                  method: "post",
                  action: "./ajax_update.php"
                }
              },
              [
                _c("input", {
                  attrs: {
                    type: "hidden",
                    id: "token",
                    name: "token",
                    value: "qAVPdXw6I0OUhbwhnNqi6wT6W0RuF1YIyg0Ypaxn"
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 col-form-label",
                      attrs: { for: "recovery_num" }
                    },
                    [_vm._v("回復値")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-auto" }, [
                    _c("input", {
                      staticClass: "form-control text-right",
                      attrs: {
                        type: "text",
                        id: "recovery_num",
                        name: "recovery_num",
                        placeholder: "回復値",
                        value: "5"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "invalid-feedback",
                      attrs: { id: "invalid-feedback-recovery_num" }
                    }),
                    _vm._v(" "),
                    _c("small", { staticClass: "form-text text-muted" }, [
                      _vm._v("1回あたりの回復量を入力してください。")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-md-2 col-form-label",
                      attrs: { for: "limit_num" }
                    },
                    [_vm._v("制限値")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-auto" }, [
                    _c("input", {
                      staticClass: "form-control text-right",
                      attrs: {
                        type: "text",
                        id: "limit_num",
                        name: "limit_num",
                        placeholder: "制限値",
                        value: "5"
                      }
                    }),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "invalid-feedback",
                      attrs: { id: "invalid-feedback-limit_num" }
                    }),
                    _vm._v(" "),
                    _c("small", { staticClass: "form-text text-muted" }, [
                      _vm._v(
                        "ユーザが与えることのできるポイントの所持上限値を入力してください。"
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group text-center" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "button",
                        onclick: "update(); return false;"
                      }
                    },
                    [_vm._v("更新")]
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal fade",
              attrs: {
                id: "ConfirmModal",
                tabindex: "-1",
                role: "dialog",
                "aria-labelledby": "ConfirmModalTitle",
                "aria-hidden": "true"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-dialog modal-dialog-centered modal-lg",
                  attrs: { role: "document" }
                },
                [
                  _c("div", { staticClass: "modal-content" }, [
                    _c("div", { staticClass: "modal-header" }, [
                      _c(
                        "h5",
                        {
                          staticClass: "modal-title",
                          attrs: { id: "ConfirmModalTitle" }
                        },
                        [_vm._v("更新確認")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: {
                            type: "button",
                            "data-dismiss": "modal",
                            "aria-label": "Close"
                          }
                        },
                        [
                          _c("span", { attrs: { "aria-hidden": "true" } }, [
                            _vm._v("×")
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-body" }, [
                      _c("div", { staticClass: "alert alert-danger mt-3" }, [
                        _c("h6", { staticClass: "alert-heading" }, [
                          _c("strong", [_vm._v("注意！")])
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "制限値を超えているユーザは制限値が適用されます。"
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v("更新します。よろしいですか？")])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { type: "button", "data-dismiss": "modal" }
                        },
                        [_vm._v("キャンセル")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button", onclick: "updateDone();" }
                        },
                        [_vm._v("更新する")]
                      )
                    ])
                  ])
                ]
              )
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }