var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.total > 1
    ? _c("div", { staticClass: "paging" }, [
        _c(
          "ul",
          { staticClass: "pagination" },
          [
            _vm.currentPage > 1
              ? _c(
                  "li",
                  {
                    class: { page: true, disabled: _vm.disabledToPrevious },
                    on: { click: _vm.toPrevious }
                  },
                  [
                    _c("a", { attrs: { href: "javascript:;" } }, [
                      _vm._v("<<\n                ")
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.pages, function(page, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: {
                    page: true,
                    active: Number.isInteger(page) && page === _vm.currentPage,
                    disabled: page === "..."
                  },
                  on: {
                    click: function($event) {
                      return _vm.changePage(page)
                    }
                  }
                },
                [
                  _c("a", { attrs: { href: "javascript:;" } }, [
                    _vm._v(_vm._s(page))
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _vm.currentPage < _vm.pages[_vm.pages.length - 1]
              ? _c(
                  "li",
                  {
                    class: { page: true, disabled: _vm.disabledToNext },
                    on: { click: _vm.toNext }
                  },
                  [
                    _c("a", { attrs: { href: "javascript:;" } }, [
                      _vm._v(">>\n                ")
                    ])
                  ]
                )
              : _vm._e()
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }