var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body" },
    [
      _c(
        "v-wait",
        { attrs: { for: "loading" } },
        [
          _c("template", { slot: "waiting" }, [
            _c("div", [
              _c("div", { staticClass: "wrap-loading" }),
              _vm._v(" "),
              _c("div", { staticClass: "v-spinner" }, [
                _c(
                  "div",
                  { staticClass: "loadingio-spinner-rolling-2n8ectvu1hk" },
                  [_c("div", { staticClass: "ldio-gf8wf4cp9ou" }, [_c("div")])]
                )
              ])
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }