var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "header",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.user.id,
            expression: "user.id"
          }
        ]
      },
      [
        _c(
          "nav",
          { staticClass: "navbar navbar-expand-lg navbar-dark" },
          [
            _c(
              "router-link",
              {
                staticClass: "navbar-brand",
                attrs: { to: { name: "AdminForm" } }
              },
              [
                _c("img", {
                  staticClass: "d-inline-block align-top avatar",
                  attrs: {
                    src: "/images/logo-02.png",
                    width: "32",
                    height: "32"
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "navbar-toggler",
                attrs: {
                  type: "button",
                  "data-toggle": "collapse",
                  "data-target": "#navbarSupportedContent",
                  "aria-controls": "navbarSupportedContent",
                  "aria-expanded": "false",
                  "aria-label": "Toggle navigation"
                },
                on: { click: _vm.toggleCollapse }
              },
              [_c("span", { staticClass: "navbar-toggler-icon" })]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "collapse navbar-collapse",
                attrs: { id: "navbarSupportedContent" }
              },
              [
                _c("ul", { staticClass: "navbar-nav ml-auto" }, [
                  _c(
                    "li",
                    { staticClass: "nav-item" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "nav-link",
                          attrs: { to: { name: "AdminForm" } }
                        },
                        [_vm._v("投稿フォーム")]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.user.is_admin
                    ? _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: { to: { name: "AdminRanking" } }
                            },
                            [_vm._v("ランキング")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user.is_admin
                    ? _c(
                        "li",
                        { staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: { to: { name: "AdminChannel" } }
                            },
                            [_vm._v("アクティビティ")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user.is_admin
                    ? _c("li", { staticClass: "nav-item dropdown" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "b-dropdown",
                              {
                                staticClass: "m-2",
                                attrs: {
                                  id: "dropdown-right",
                                  right: "",
                                  text: "Admin",
                                  variant: "primary"
                                }
                              },
                              [
                                _c(
                                  "b-dropdown-item",
                                  { attrs: { to: { name: "AdminUser" } } },
                                  [_vm._v("ユーザー管理")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-dropdown-item",
                                  { attrs: { to: { name: "AdminAdjust" } } },
                                  [_vm._v("ポイント調整管理")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-dropdown-item",
                                  { attrs: { to: { name: "AdminWorksheet" } } },
                                  [_vm._v("勤怠シートアップロード")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "nav-item dropdown" },
                    [
                      _c(
                        "b-dropdown",
                        {
                          staticClass: "m-2",
                          attrs: {
                            id: "dropdown-right",
                            right: "",
                            variant: "primary"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "button-content",
                              fn: function() {
                                return [
                                  _c("img", {
                                    staticStyle: {
                                      width: "24px",
                                      "margin-right": "5px"
                                    },
                                    attrs: {
                                      src: _vm.user.image,
                                      onerror:
                                        "this.src='/images/generic_avatar.png'",
                                      alt: _vm.user.real_name
                                    }
                                  }),
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.user.real_name) +
                                      "\n                            "
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: {
                                  name: "AdminUserDetail",
                                  params: { id: _vm.user.id }
                                }
                              }
                            },
                            [_vm._v("プロフィール")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  return _vm.logout()
                                }
                              }
                            },
                            [_c("span", [_vm._v("ログアウト")])]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }