import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import persistedState from "vuex-persistedstate";
import Cookies from "js-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user
    },
    plugins: [
        persistedState({
            paths: ['client'],
            storage: {
                getItem: key => Cookies.get(key),
                setItem: (key, value) => Cookies.set(key, value),
                removeItem: key => Cookies.remove(key, { path: '/', domain: window.location.hostname })
            }
        }),
    ],
})
