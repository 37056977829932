<template>
  <div>
    <admin-header></admin-header>
    <div class="container">
      <div id="Worksheet-content">
        <div id="content">
          <div class="row my-3 tittle-area-p">
            <div class="col-auto tittle-area"><img src="/images/worksheet.png" alt="ランキング"><span class="h4" style="width: auto; justify-content: center;">勤怠シートアップロード</span></div>
          </div>
          <div id="search_result">
            <div class="row">
              <div class="alert col-12 col-md-6 col-lg-6">
                <span v-if="list.total > 0">{{ list.total }} 件中 {{ list.per_page * (page - 1) + 1 }} ～ {{ list.per_page * page <= list.total ? list.per_page * page : list.total }} 件目を表示しています。</span>
              </div>
              <div class="col-12 col-md-6 col-lg-6 text-right date-block">
                <button class="btn btn-info mb-3" @click="showPopupImport">⬆︎ 勤怠シートアップロード</button>
              </div>
            </div>
            <div class="table-responsive ranking_index">
              <table class="table table-bordered table-striped table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th width="10%" class="rank text-center">対象月</th>
                    <th width="30%" class="person text-center">ファイル名</th>
                    <th width="20%" class="text-center">アップロード日時</th>
                    <th width="20%" class="total text-center">ダウンロード</th>
                  </tr>
                </thead>
                <tbody v-if="list.total > 0">
                  <tr v-for="(item, i) in list.data">
                    <td class="rank align-middle text-center">{{ formatDate(item.year_month) }}</td>
                    <td class="total align-middle text-right">
                      <a class="a" :href="item.file_path" download>
                        {{ basename(item.file_path) }}
                      </a>
                    </td>
                    <td class="total align-middle text-right">{{ item.created_at ? formatDateTime(item.created_at) : '' }}</td>
                    <td class="total align-middle text-right">
                      <a class="btn btn-success" :href="item.file_path" download>
                        ダウンロード
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="100">データがありません。</td>
                  </tr>
                </tbody>
              </table>
              <div class="padding-all ranking-pagination" v-if="list">
                <pagination :current-page="list.current_page"
                :per-page="list.per_page"
                :total="list.total"
                @changePage="changePage"
                v-if="list.total > 1"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="popupImport" id="popup-import" :modal-class="['edit-modal']" title="勤怠シートアップロード" :centered="true" :no-close-on-backdrop="true" header-close-label="" hide-footer>
      <ValidationObserver v-slot="{ passes }" class="w-100" ref="form">
        <form @submit.prevent="passes(importExcel)" method="post" class="training-park-form" >
          <div class="modal-content border-0">
            <div class="modal-body">
              <b-alert 
                dismissible
                fade
                :show="dismissCountDown"
                :variant="variant"
                @dismiss-count-down="countDownChanged"
                >
                {{ errorMessage }}
              </b-alert>
              <h5 class="">ファイルを選ぶ</h5>
              <div class="form-group">
                <ValidationProvider vid="file" class="w-100" name="ファイル" rules="">
                  <div slot-scope="{ errors }" class="w-100">
                    <input ref="fileText" type="file" class="form-control p-1" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" @change="readExcel" />
                    <p class="text-danger mb-0">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="modal-footer center border-0">
              <button type="button" class="btn btn-secondary" @click="$bvModal.hide('popup-import')">キャンセル</button>
              <button type="submit" class="btn btn-success btn-actions">アップロード</button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
  import moment from 'moment';
  export default {
    data() {
      return {
        selectedMonth: 1,
        list: [],
        page: 1,
        perPage: 5,
        excel: {},
        dismissCountDown: 0,
        errorMessage: '',
        variant: 'danger',
      }
    },
    async created() {
      await this.getData();
    },
    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown;
      },
      showAlert(data) {
        this.errorMessage = data.message;
        this.variant = data.status ? 'success' : 'danger';
        this.dismissCountDown = 5;
      },
      basename(path) {
        var name = path.split('/').reverse()[0];

        return name;
      },
      formatDate(date) {
        return date.substr(0, 4) + '/' + date.replace(date.substr(0, 4), '');
      },
      formatDateTime(date) {
        return moment(date).format('YYYY/MM/DD HH:mm');
      },
      showPopupImport() {
        this.excel = {};
        this.dismissCountDown = 0;
        this.$refs.popupImport.show();
      },
      readExcel() {
        this.$refs.form.reset();
        var target = event.currentTarget;
        var files = event.target.files;
        var fr = new FileReader();

        if (typeof files[0] == 'undefined') {
          this.excel = {};
          this.$refs.fileText.value = '';
          this.$refs.form.setErrors({
            file: ['このファイルを読み取れません']
          });
          return false;
        }
        const lastDot = files[0].name.lastIndexOf('.');
        this.excel.filename = files[0].name.substring(0, lastDot);
        this.excel.extension = files[0].name.substring(lastDot);

        if (['.xls', '.xlsx'].indexOf(this.excel.extension.toLowerCase()) == -1) {
          this.excel = {};
          this.$refs.fileText.value = '';
          this.$refs.form.setErrors({
            file: ['このファイルを読み取れません']
          });
          return false;
        }

        fr.onload = () => {
          this.excel.src = fr.result;
        };
        fr.readAsDataURL(files[0]);

        return true;
      },
      async importExcel() {
        console.log(this.excel)
        if (_.isEmpty(this.excel)) {
          this.$refs.form.setErrors({
            file: ['ファイルは必須項目です']
          });
          return false;
        }
        try {
          this.$wait.start('loading');

          const response = await axios.post('/api/worksheet/import', this.excel);
          this.$wait.end('loading');

          this.showAlert(response.data);
        } catch (res) {
          this.$refs.form.setErrors({
            file: [res.response.data]
          });
        }
      },
      async changePage(page) {
        this.page = page;
        await this.getData();
      },
      async getData() {
        const response = await axios.get(`/api/worksheet/list?page=${this.page}`);
        this.list = response.data.list;
        this.perPage = response.data.per_page;
      }
    }
  };
</script>

<style lang="scss">
  #popup-import .alert-danger {
    background-color: #f8d7da;
    border-color: #f8d7da;
  }
</style>