<template>
    <div>
        <admin-header></admin-header>
        <div class="container">
            <div id="Ranking-content">
                <div id="content">
                    <div class="row my-3 tittle-area-p">
                        <div class="col-auto tittle-area"><img src="/images/ranking_icon.png" alt="ランキング"><span class="h4">- ランキング -</span></div>
                    </div>
                    <div id="search_result">
                        <div class="row">
                            <div class="alert col-12 col-md-9 col-lg-10">
                                <span v-if="users.total > 0">{{ users.total }} 件中 {{ users.per_page * (page - 1) + 1 }} ～ {{ users.per_page * page <= users.total ? users.per_page * page : users.total }} 件目を表示しています。</span>
                                <span v-else>データがありません。</span>
                            </div>
                            <div class="col-12 col-md-3 col-lg-2 text-right date-block">
                                <select @change="getData" class="form-control" v-model="selectedMonth">
                                    <option v-for="item in months" :value="item.id">{{ item.text }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="table-responsive ranking_index">
                            <table class="table table-bordered table-striped table-hover">
                                <thead class="thead-dark">
                                    <tr>
                                        <th width="10%" rowspan="2" class="rank text-center">ランク</th>
                                        <th width="30%" rowspan="2" class="person text-center">ユーザー</th>
                                        <th width="20%" colspan="2" class="text-center">投稿数</th>
                                        <th width="20%" rowspan="2" class="total text-center">出勤日数</th>
                                        <th width="10%" rowspan="2" class="total text-center">ポイント</th>
                                    </tr>
                                    <tr>
                                        <th class="text-center">してあげた</th>
                                        <th class="text-center">してもらった</th>
                                    </tr>
                                </thead>
                                <tbody v-if="users.total > 0">
                                    <tr v-for="(user, index) in users.data">
                                        <td class="rank align-middle text-center">{{ parseInt(index) + 1 }}</td>
                                        <td class="person align-middle text-left">
                                            <!--<router-link :to="{ name: 'AdminUserDetail', params: {id: 1} }">-->
                                            <a href="javascript:;">
                                                <img style="width: 48px;margin-right: 10px;" :src="user.image" onerror="this.src='/images/generic_avatar.png'" alt="">
                                                <span>{{ user.real_name }}</span>
                                            </a>
                                            <!--</router-link>-->
                                        </td>
                                        <td class="total align-middle text-right">{{ user.to }}</td>
                                        <td class="total align-middle text-right">{{ user.from }}</td>
                                        <td class="total align-middle text-right">{{ user.working_days }}</td>
                                        <td class="total align-middle text-right">{{ user.points }} <img width="25px" src="/images/heart.png" alt=""></td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="100">データがありません。</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="padding-all ranking-pagination" v-if="users">
                                <pagination :current-page="users.current_page"
                                            :per-page="users.per_page"
                                            :total="users.total"
                                            @changePage="changePage"
                                            v-if="users.total > 1"
                                ></pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<admin-footer></admin-footer>-->
    </div>
</template>

<script>
    import moment from 'moment';
    export default {
        data() {
            return {
                selectedMonth: 1,
                months: [],
                users: [],
                page: 1,
                perPage: 5,
            }
        },
        async created() {
            var curMonth = moment().month() + 1;
            var curYear = moment().year();
            
            for (var i = curMonth; i > (curMonth - 7); i--) {
                this.months.push({
                    id: (curMonth + 1) - i,
                    text: `${curYear}/${i.toString().padStart(2, '0')}`,
                });
            }
            await this.getData();
        },
        methods: {
            getMonth() {
                return this.months.find(obj => obj.id == this.selectedMonth);
            },
            async changePage(page) {
                this.page = page;
                await this.getData();
            },
            async getData() {
                const response = await axios.get(`/api/points/ranking?page=${this.page}&month=` + this.getMonth().text);
                this.users = response.data.users;
                this.perPage = response.data.per_page;
            }
        }
    };
</script>

