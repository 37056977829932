<template>
    <div>
        <admin-header></admin-header>
        <div class="container">
            <div id="Admin-settings">
                <div id="content">
                    <div class="row my-3">
                        <div class="col-auto"><span class="h4">設定</span></div>
                    </div>
                    <div>
                        <form id="form_update" method="post" action="./ajax_update.php">
                            <input type="hidden" id="token" name="token" value="qAVPdXw6I0OUhbwhnNqi6wT6W0RuF1YIyg0Ypaxn">
                            <div class="form-group row">
                                <label for="recovery_num" class="col-md-2 col-form-label">回復値</label>
                                <div class="col-md-auto">
                                    <input type="text" class="form-control text-right" id="recovery_num" name="recovery_num" placeholder="回復値" value="5">
                                    <div class="invalid-feedback" id="invalid-feedback-recovery_num"></div>
                                    <small class="form-text text-muted">1回あたりの回復量を入力してください。</small>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="limit_num" class="col-md-2 col-form-label">制限値</label>
                                <div class="col-md-auto">
                                    <input type="text" class="form-control text-right" id="limit_num" name="limit_num" placeholder="制限値" value="5">
                                    <div class="invalid-feedback" id="invalid-feedback-limit_num"></div>
                                    <small class="form-text text-muted">ユーザが与えることのできるポイントの所持上限値を入力してください。</small>
                                </div>
                            </div>
                            <div class="form-group text-center">
                                <button type="button" class="btn btn-primary" onclick="update(); return false;">更新</button>
                            </div>
                        </form>
                    </div>
                    <div class="modal fade" id="ConfirmModal" tabindex="-1" role="dialog" aria-labelledby="ConfirmModalTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="ConfirmModalTitle">更新確認</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <div class="alert alert-danger mt-3">
                                        <h6 class="alert-heading"><strong>注意！</strong></h6>
                                        <p>制限値を超えているユーザは制限値が適用されます。</p>
                                    </div>
                                    <p>更新します。よろしいですか？</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-dismiss="modal">キャンセル</button>
                                    <button type="button" class="btn btn-danger" onclick="updateDone();">更新する</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<admin-footer></admin-footer>-->
    </div>
</template>

<script>
    export default {
    };
</script>

