var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "signin-body" }, [
    _c("div", [
      _c("h5", { staticClass: "title" }, [_vm._v("一日一善システム")]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn-primary signin",
          attrs: {
            href:
              "https://slack.com/oauth/v2/authorize?client_id=" +
              _vm.MIX_SLACK_CLIENT_ID +
              "&user_scope=identity.basic,identity.avatar,identity.email,identity.team&redirect_uri=" +
              _vm.MIX_SLACK_REDIRECT_URI
          }
        },
        [
          _c("i", {
            staticClass: "fab fa-slack",
            attrs: { "data-v-3038f62f": "" }
          }),
          _vm._v(" Slackでログイン\n        ")
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }