<template>
    <div>
        <header v-show="user.id">
            <nav class="navbar navbar-expand-lg navbar-dark">
                <router-link class="navbar-brand" :to="{ name: 'AdminForm' }">
                    <img src="/images/logo-02.png" width="32" height="32" class="d-inline-block align-top avatar">
                </router-link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" @click="toggleCollapse">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <router-link class="nav-link" :to="{name: 'AdminForm'}">投稿フォーム</router-link>
                        </li>
                        <li class="nav-item"  v-if="user.is_admin">
                            <router-link class="nav-link" :to="{name: 'AdminRanking'}">ランキング</router-link>
                        </li>
                        <li class="nav-item"  v-if="user.is_admin">
                            <router-link class="nav-link" :to="{name: 'AdminChannel'}">アクティビティ</router-link>
                        </li>
                        <!--<li class="nav-item">-->
                            <!--<a class="nav-link" href="/rewards/">報酬</a>-->
                        <!--</li>-->
                        <li class="nav-item dropdown" v-if="user.is_admin">
                            <div>
                                <b-dropdown id="dropdown-right" right text="Admin" variant="primary" class="m-2">
                                    <!--<b-dropdown-item href="#">アナリティクス</b-dropdown-item>-->
                                    <b-dropdown-item :to="{name: 'AdminUser'}">ユーザー管理</b-dropdown-item>
                                    <b-dropdown-item :to="{name: 'AdminAdjust'}">ポイント調整管理</b-dropdown-item>
                                    <b-dropdown-item class="" :to="{name: 'AdminWorksheet'}">勤怠シートアップロード</b-dropdown-item>
                                    <!--<b-dropdown-item href="#">報酬管理</b-dropdown-item>-->
                                    <!--<b-dropdown-item href="#">引き換え管理</b-dropdown-item>-->
                                    <!--<b-dropdown-item :to="{name: 'AdminSetting'}">設定</b-dropdown-item>-->
                                </b-dropdown>
                            </div>
                        </li>
                        <li class="nav-item dropdown">
                            <b-dropdown id="dropdown-right" right variant="primary" class="m-2">
                                <template v-slot:button-content>
                                    <img style="width: 24px;margin-right: 5px;" :src="user.image" onerror="this.src='/images/generic_avatar.png'" :alt="user.real_name">
                                    {{ user.real_name }}
                                </template>
                                <b-dropdown-item :to="{name: 'AdminUserDetail', params: {id: user.id }}">プロフィール</b-dropdown-item>
                                <b-dropdown-item href="#" @click="logout()"><span>ログアウト</span></b-dropdown-item>
                            </b-dropdown>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    export default {
        computed: {
            ...mapState('user', ['user'])
        },
        data() {
            return {
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('user/AUTH_LOGOUT').then(() => {
                    this.$router.push({
                        name: 'Home'
                    })
                })
            },
            toggleCollapse() {
                var target = event.currentTarget.getAttribute('data-target');
                var ele = document.querySelector(target);

                if (ele) {
                    if (ele.classList.contains('show')) {
                        ele.classList.remove('show');
                        return true;
                    }
                    ele.classList.add('show');
                }
            },
        },
        created() {
        }
    };
</script>

<style lang="scss">
@media screen and (max-width: 991px) {
    #navbarSupportedContent .nav-item.dropdown {
        .b-dropdown {
            flex-wrap: wrap;
        }
        .dropdown-menu {
            transform: translate3d(0px, 68px, 0px) !important;
            position: relative !important;
            transform: unset !important;
            width: 100%;
            will-change: unset !important;
            top: 1px !important;
            width: 100%;
            background: transparent;
            border: none;

            li {
                margin: 0;
                width: 100%;

                a {
                    color: rgba(255, 255, 255, 0.5);
                }
            }
        }
    }
}
</style>