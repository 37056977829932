<template>
    <div>
        <admin-header></admin-header>
        <div class="container">
            <div id="Activity-content">
                <div id="content">
                    <div class="row my-3">
                        <div class="col-auto"><span class="h4 align-items-center d-flex"><img class="mr-1" width="25px" src="/images/heart.png" alt="">ポイント調整</span></div>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <button type="button" class="btn btn-primary" @click="showPopup()">新規登録</button>
                        </div>
                    </div>
                    <div id="search_result">
                        <div>
                            <div class="alert alert-info px-0 l-0">
                                <span v-if="adjusts  && adjusts.data && adjusts.data.length > 0">{{ adjusts.total }} 件中 {{ adjusts.per_page * (page - 1) + 1}} ～ {{ adjusts.per_page * page <= adjusts.total ? adjusts.per_page * page : adjusts.total }} 件目を表示しています。</span>
                                <span v-else>データがありません。</span>
                            </div>
                        </div>
                        <div class="table-responsive admin_users_index mt-2">
                            <table class="table table-bordered table-striped table-hover">
                                <thead class="thead-dark">
                                <tr>
                                    <th class="to_user text-center">対象ユーザー</th>
                                    <th class="num text-center">増減値</th>
                                    <th class="from_user text-center">実行ユーザー</th>
                                    <th class="reason text-center only-pc">理由</th>
                                    <th class="created text-center only-pc">日時</th>
                                </tr>
                                </thead>
                                <tbody v-if="adjusts && adjusts.data && adjusts.data.length > 0">
                                <tr v-for="adjustInfo in adjusts.data">
                                    <td class="person align-middle text-left">
                                        <router-link :to="{name: 'AdminUserDetail', params: {id: adjustInfo.receive_user.id}}">
                                            <img style="width: 48px;margin-right: 10px;" :src="adjustInfo.receive_user.image" onerror="this.src='/images/generic_avatar.png'" alt="BSV Vu Truong">
                                            <span>{{ adjustInfo.receive_user.real_name }}</span>
                                        </router-link>
                                    </td>
                                    <td class="num align-middle text-center"><span class="mr-1">{{ adjustInfo.num }}</span><img width="25px" src="/images/heart.png" alt=""></td>
                                    <td class="person align-middle text-left">
                                        <router-link :to="{name: 'AdminUserDetail', params: {id: adjustInfo.send_user.id}}">
                                            <img style="width: 48px;margin-right: 10px;" :src="adjustInfo.send_user.image" onerror="this.src='/images/generic_avatar.png'" alt="Thang Nguyen">
                                            <span>{{ adjustInfo.send_user.real_name }}</span>
                                        </router-link>
                                    </td>
                                    <td class="reason align-middle text-left only-pc">{{ adjustInfo.reason }}</td>
                                    <td class="created align-middle text-center only-pc"><span>{{ adjustInfo.created_at | datetimeFormat }}</span></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="padding-all" v-if="adjusts">
                            <pagination :current-page="adjusts.current_page"
                                        :per-page="adjusts.per_page"
                                        :total="adjusts.total"
                                        @changePage="changePage"
                                        v-if="adjusts.last_page > 1"
                            ></pagination>
                        </div>
                    </div>

                    <b-modal name="adjust" id="adjust" ref="adjust" :modal-class="['modal-action']" :centered="true"
                             :no-close-on-backdrop="true" header-close-label="" hide-footer hide-header>
                        <div class="modal-header">
                            <h5 class="modal-title" id="editModalTitle">調整情報</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="$bvModal.hide('adjust')">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form"
                                            @submit.prevent="addAdjust()">
                            <div class="modal-body">
                                <input type="hidden" name="token" value="jW3XzHGkRxbCc7aAzfCoyC6MFkcxEuEV5fJdscWQ">
                                <div class="form-group row">
                                    <label for="user_id" class="col-lg-3 col-form-label">ユーザー<span
                                        class="badge badge-danger ml-2">必須</span></label>
                                    <div class="col-form-label col-lg-4 col-md-4">
                                        <ValidationProvider :mode="isSubmit ? 'eager': 'passive'" name="user" rules="required" v-slot="{ errors }">
                                            <v-select placeholder="ユーザー"
                                                      :class="{ 'v-select': true, 'error': errors[0] }"
                                                      v-model="adjust.receive"
                                                      :options="users"></v-select>
                                            <span class="error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                        <div class="invalid-feedback" id="invalid-feedback-user_id" style="display: block;"></div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="num" class="col-lg-3 col-form-label">増減値<span class="badge badge-danger ml-2">必須</span></label>
                                    <div class="col-lg-4 col-md-4">
                                        <ValidationProvider :mode="isSubmit ? 'eager': 'passive'" name="num" :rules="{'required': true, 'regex': /[0-9|\-]/}" v-slot="{ errors }">
                                            <input
                                                type="number"
                                                :class="{ 'form-control text-right': true, 'error': errors[0] }"
                                                id="num" name="num"
                                                placeholder="増減値" v-model="adjust.num">
                                            <span class="error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                        <div class="invalid-feedback" id="invalid-feedback-num"></div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="reason" class="col-lg-3 col-form-label">理由<span class="badge badge-danger ml-2">必須</span></label>
                                    <div class="col-lg-9">
                                        <ValidationProvider :mode="isSubmit ? 'eager': 'passive'" name="reason" rules="required|max:200" v-slot="{ errors }">
                                            <textarea :class="{ 'form-control reason': true, 'error': errors[0] }"
                                                      id="reason" name="reason"
                                                      placeholder="理由" maxlength="200"
                                                      rows="3" v-model="adjust.reason"></textarea>
                                            <span class="error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                        <div class="invalid-feedback" id="invalid-feedback-reason"></div>
                                        <small class="form-text text-muted">
                                            200文字以内で入力してください。
                                        </small>
                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer top-30">
                                <b-button class="btn-submit" @click="$bvModal.hide('adjust')">キャンセル</b-button>
                                <button type="submit" class="btn btn-primary" @click="">登録する</button>
                            </div>
                        </ValidationObserver>
                    </b-modal>
                </div>
            </div>
        </div>
        <!--<admin-footer></admin-footer>-->
    </div>
</template>

<script>
    import {mapActions, mapMutations, mapState} from 'vuex'

    export default {
        data() {
            return {
                adjusts: {},
                adjust: {
                    num: null,
                    reason: null
                },
                isSubmit: false,
                page: 1
            }
        },
        computed: {
            ...mapState('user', ['users', 'user']),
        },
        methods: {
            ...mapMutations('user', ['SET_USERS']),
            ...mapActions('user', ['LIST_USERS']),
            showPopup() {
                this.$refs.adjust.show()
            },
            changePage(page) {
                this.page = page
                this.$router.push({name: 'AdminAdjust', query: {page: this.page}})
                this.getAdjust()
            },
            async addAdjust() {
                this.isSubmit = true
                const isValid = await this.$refs.observer.validate();
                if (!isValid) {
                    setTimeout(() => {
                        const errors = Object.entries(this.$refs.observer.errors)
                            .map(([key, value]) => ({key, value}))
                            .filter(error => error["value"].length);
                        this.$refs.observer.refs[errors[0]["key"]].$el.scrollIntoView({
                            behavior: "smooth",
                            block: "center"
                        });
                    }, 100);
                    return
                }

                this.$wait.start('loading')
                this.adjust.sender_id = this.user.id
                this.adjust.receive_id = this.adjust.receive.code
                delete this.adjust.receive
                let {data} = await axios.post('/api/adjusts/create', this.adjust)
                if (data.id) {
                    this.isSubmit = false
                    this.$refs.observer.reset()
                    this.adjust.num = null
                    this.adjust.reason = null
                }

                this.getAdjust()
                this.page = 1
                if (this.page > 1) {
                    this.$router.push({name: 'AdminAdjust', query: {page: this.page}});
                }
                this.$refs.adjust.hide()
                this.$wait.end('loading')

            },
            async getAdjust() {
                let {data} = await axios.get('/api/adjusts?page='+this.page)
                this.adjusts = data
            }
        },
        created() {
            this.LIST_USERS()
            this.page = this.$route.query.page ? this.$route.query.page : 1
            this.getAdjust()
        }
    };
</script>

